import styled from '@emotion/styled';

import logo from '../assets/usf-logo.svg';

export default styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background: url(${logo}) no-repeat;
`;
